import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import Block from "~/components/Block";
import BlockSlider from "~/components/Block/BlockSlider";
import Card from "~/components/Card";
import {
  getCommonSliceProps,
  getRichText,
  resolveDocumentLink,
  resolveLink,
  resolveMedia,
} from "~/external/prismic";
import connectSlice from "~/lib/connectSlice";

type CardType = {
  media?: any;
  title?: string;
  subtitle?: string;
  text?: string;
  buttonText?: string;
  // link?: any;
  href?: string;
  hrefAs?: string;
  hrefTarget?: string;
};

type PropType = {
  spacingTop?: number;
  spacingBottom?: number;
  backgroundColor?: string;
  color?: string;
  animateIn?: boolean;
  buttonType?: string;
  buttonAction?: string;
  // cards?: [CardType];
  cards?: any;
  type?: string;
  visibleCards?: number;
};

const cardVariants = {
  out: { opacity: 0, y: "100%" },
  in: {
    opacity: 1,
    y: 0,
    transition: {
      type: "tween",
      ease: [0.23, 1, 0.32, 1],
      duration: 2,
    },
  },
};

function CardSliderSlice(props: PropType) {
  const {
    spacingTop = 0,
    spacingBottom = 6,

    backgroundColor,
    color,

    type = "a",
    cards = [],
    visibleCards = 2.5, // 40vw
    buttonType = "outlined",
    buttonAction = "add to cart",
    animateIn = false,
  } = props;

  // console.log(animateIn);

  const [ref, inView] = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  let animate = inView ? "in" : "out";
  if (!animateIn) {
    animate = "in";
  }

  return (
    <div ref={ref}>
      <Block
        spacingTop={spacingTop}
        spacingBottom={spacingBottom}
        backgroundColor={backgroundColor}
        color={color}
      >
        <motion.div variants={cardVariants} animate={animate}>
          <BlockSlider size={`${100 / visibleCards}vw`}>
            {cards.map((card: CardType, i) => (
              <Card
                key={i}
                color={color}
                type={type}
                buttonType={buttonType}
                lazy
                {...card}
              />
            ))}
          </BlockSlider>
        </motion.div>
      </Block>
    </div>
  );
}

export function mapSliceToProps(slice, externalData) {
  const { primary, items = [] } = slice;

  // console.log('🍕', slice);
  // console.log("📦", items);

  const props = {
    ...getCommonSliceProps(slice),

    type: primary.card_type && primary.card_type.charAt(0).toLowerCase(), // getType(primary.card_type), // primary.card_type === 'A' ? 'a' : 'b', // card_type
    buttonType: primary.button_type && primary.button_type.toLowerCase(),
    visibleCards: primary.visible_cards || 2.5,

    cards: items.map((item) => {
      // if we have a document link, pull data from that!
      // except if the fields are already defined,....
      if (item.document_link) {
        item = resolveDocumentLink(item);
      }

      const [href, hrefAs, hrefTarget] = resolveLink(item.link);

      return {
        media: resolveMedia(item, "1:1"), // resolve image and video

        title: item.title,
        subtitle: item.subtitle,
        text: getRichText(item.text),

        buttonText: item.button_text,

        href,
        hrefAs,
        hrefTarget,
      };
    }),
  };

  if (primary.sort_order === "Reversed") {
    props.cards.reverse();
  }

  return props;
}

export default connectSlice(mapSliceToProps)(CardSliderSlice);
